import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createStore, { setAsCurrentStore }  from 'store/createStore';
import toString from 'router/toString';
import {getPrefix ,getInitialState} from "@karpeleslab/klbfw";
import {BrowserRouter} from "react-router-dom";

import 'i18n';

// router

// style
import 'assets/css/bootstrap.min.css';
import 'assets/fonts/opensans/stylesheet.css';
import 'sass/index.scss';
import 'assets/css/style.css';
import 'assets/css/style-gradient.css';


// container
import Layout from 'components/Layouts/Layout';

export function run() {
  const store = createStore(getInitialState());
  setAsCurrentStore(store);
  ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={getPrefix()}>
          <Layout />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
}

export const renderToString = toString;
