
export const ADD_HASH = 'ADD_HASH'
export const CHANGE_MENU = 'CHANGE_MENU'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const INPUT_CHANGE = 'INPUT_CHANGE'
export const SEND_FLG_CHANGE = 'SEND_FLG_CHANGE'
export const VALID_FORM = 'VALID_FORM'
export const ERROE_MESSAGE = 'ERROE_MESSAGE'

// Cms

export const SYNC_CMS = 'SYNC_CMS';
export const SYNC_CMS_DONE = 'SYNC_CMS_DONE';
export const SYNC_CMS_ERROR = 'SYNC_CMS_ERROR';


// contact
export const SENDING = 'SENDING';
export const FROM_INIT = 'FROM_INIT';
