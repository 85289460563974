import * as Common from 'constants/Constants';
import {getLocale} from "@karpeleslab/klbfw";

let defaultLang = getLocale();

const initialState = {
  lang: defaultLang,
  currentMenu: 'home',
  toggleMenu: false,
  form : {
    inputs: {
      to: "sppt-vfnwmg-mjm5-e2rl-m7ws-75gbjwpq",
      name: "",
      email: "",
      subject: "",
      message: ""
    },
    error: {
      name: false,
      email: false,
      subject: false,
      message: false
    },
    sending: false,
    sendFlg: false,
    errorMessage: ''
  }
}

export const commonReducer = (state = initialState, action) => {
  switch(action.type) {
      case Common.ADD_HASH: {
          return Object.assign({}, state, {
              hash: action.hash
          })
        }
      case Common.CHANGE_MENU: {
          return Object.assign({}, state, {
            currentMenu: action.currentMenu
          })
        }
      case Common.TOGGLE_MENU: {
          return Object.assign({}, state, {
            toggleMenu: action.toggleMenu
          })
        }
      case Common.SEND_FLG_CHANGE: {
          return Object.assign({}, state, {
            form: {
              ...state.form,
              sendFlg: action.value
            }
          })
        }
      case Common.SENDING: {
          return Object.assign({}, state, {
            form: {
              ...state.form,
              sending: action.value
            }
          })
        }
      case Common.ERROE_MESSAGE: {
          return Object.assign({}, state, {
            form: {
              ...state.form,
              errorMessage: action.value
            }
          })
        }
      case Common.INPUT_CHANGE: {
          return Object.assign({}, state, {
            form: {
              ...state.form,
              inputs: {
                ...state.form.inputs,
                ...action.value
              }
            }
          });
        }
        case Common.VALID_FORM: {
            return Object.assign({}, state, {
              form: {
                ...state.form,
                error: {
                  ...action.value
                }
              }
            });
          }
          case Common.FROM_INIT: {
              return Object.assign({}, state, {
                form: {
                  inputs: {
                    to: "sppt-vfnwmg-mjm5-e2rl-m7ws-75gbjwpq",
                    name: "",
                    email: "",
                    subject: "",
                    message: ""
                  },
                  error: {
                    name: false,
                    email: false,
                    subject: false,
                    message: false
                  },
                  sending: false,
                  sendFlg: state.form.sendFlg,
                  errorMessage: ''
                }
              });
            }
      default:
          return state
  }
}