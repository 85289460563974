import React from 'react';
import ReactHelmet from "react-helmet";

const Helmet = () => {
    return (
        <ReactHelmet>
            <meta charSet="utf-8"/>
            <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
            <meta name="description" content="Karpelès Lab Inc." />
            <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1, user-scalable=0" />
            <meta name="theme-color" content="#000000"/>
        </ReactHelmet>
    );
};

export default Helmet;