import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames'
import {
  Switch,
  Route
} from "react-router-dom";

import routes from 'router/routeConfig'

// components
import Header from 'components/Layouts/Header/';
import Footer from 'components/Layouts/Footer';
import Helmet from "components/Layouts/Helmet";
import Particles from 'react-particles-js';


import styles from 'sass/Layout.module.scss';

class Layout extends React.Component {
  render() {

    const { state:{common: {toggleMenu}}} = this.props;

    return (
      <div>
        <Particles
          className={classNames('page-cover', {
            'menu-visible' : toggleMenu
        })}
          params={{
            "particles": {
              "number": {
                "value": 80,
                "density": {
                  "enable": true,
                  "value_area": 800
                }
              },
              "color": {
                "value": "#000000"
              },
              "shape": {
                "type": "circle",
                "polygon": {
                  "nb_sides": 5
                },
              },
              "opacity": {
                "value": 0.1,
                "random": false,
                "anim": {
                  "enable": false,
                  "speed": 1,
                  "opacity_min": 0.1,
                  "sync": false
                }
              },
              "size": {
                "value": 5,
                "random": true,
                "anim": {
                  "enable": false,
                  "speed": 40,
                  "size_min": 0.1,
                  "sync": false
                }
              },
              "line_linked": {
                "enable": true,
                "distance": 150,
                "color": "#000",
                "opacity": 0.1,
                "width": 1
              },
              "move": {
                "enable": true,
                "speed": 3,
                "direction": "none",
                "random": false,
                "straight": false,
                "out_mode": "out",
                "attract": {
                  "enable": false,
                  "rotateX": 600,
                  "rotateY": 1200
                }
              }
            },
            "interactivity": {
              "detect_on": "canvas",
              "events": {
                "onhover": {
                  "enable": true,
                  "mode": "repulse"
                },
                "onclick": {
                  "enable": true,
                  "mode": "push"
                },
                "resize": true
              },
              "modes": {
                "grab": {
                  "distance": 400,
                  "line_linked": {
                    "opacity": 1
                  }
                },
                "bubble": {
                  "distance": 400,
                  "size": 40,
                  "duration": 2,
                  "opacity": 8,
                  "speed": 3
                },
                "repulse": {
                  "distance": 200
                },
                "push": {
                  "particles_nb": 4
                },
                "remove": {
                  "particles_nb": 2
                }
              }
            },
            "retina_detect": true,
            "config_demo": {
              "hide_card": false,
              "background_color": "#b61924",
              "background_image": "",
              "background_position": "50% 50%",
              "background_repeat": "no-repeat",
              "background_size": "cover"
            }
        }} />
        <Header />
        <div
          className={classNames(styles['section-group'], {
            [styles['menu-visible']] : toggleMenu
          })}>
          <Switch>
              {
                  routes.map((route, idx) => {
                      switch (route.type) {
                          case 'route':
                          default:
                              return <Route key={idx} {...route} />
                      }
                  })}
          </Switch>
        </div>
        <Footer />
        <Helmet/>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
      state,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Layout);
