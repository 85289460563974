import React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { getPrefix} from "@karpeleslab/klbfw";
import { useLocation } from "react-router-dom";


// asset
import FlagEn from 'assets/img/flag-icons/en-US.png';
import FlagJP from 'assets/img/flag-icons/ja-JP.png';


function Test() {
  const location = useLocation();
  return (
    <div className="footer-left">
      <a
          href={`/l/en-US${location.pathname}`}
          hrefLang="en-US"
          className="flag"
          >
            <img
              src={FlagEn}
              alt="English (US)"
              title="英語 (アメリカ合衆国)"
              className={classNames({
                'is-active' : getPrefix() === '/l/en-US'
              })}
            />  
        </a>

        <a
          href={`/l/ja-JP${location.pathname}`}
          hrefLang="ja-JP"
          className="flag"
        >
            <img
              src={FlagJP}
              alt="日本語"
              title="日本語 (日本)"
              className={classNames({
                'is-active' : getPrefix() === '/l/ja-JP'
              })}
            />  
        </a>
    </div>
  );
}

class Footer extends React.Component {
  render() {
    return (
      <footer id="site-footer" className="page-footer">
        <Test />
        <div className="footer-right">
          <p className="note"><Trans i18nKey="footer_copyright">© 2019 <a href='https://klb.jp'><span className='marked'>カルプレス研究所</span></a> All rights reserved.</Trans>
          </p>
        </div>
    </footer>
    );
  }
};

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
