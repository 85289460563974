import Home from "components/Pages/Home/Home";
import Skills from "components/Pages/Skills/Skills";
import Project from "components/Pages/Project/Project";
import Team from "components/Pages/Team/Team";
import Contact from "components/Pages/Contact/Contact";
import Cms from "components/Pages/Cms/Cms";

export default [
    {
        path: "/",
        component: Home,
        exact: true,
        type: 'route'
    },
    {
        path: "/skills",
        component: Skills,
        exact: false,
        type: 'route'
    },
    {
        path: "/projects",
        component: Project,
        exact: false,
        type: 'route'
    },
    {
        path: "/team",
        component: Team,
        exact: false,
        type: 'route'
    },
    {
        path: "/contact",
        component: Contact,
        exact: false,
        type: 'route'
    },
    {
        path: "/page/:key",
        component: Cms,
        exact: false,
        loadData: [Cms.serverFetch],
        type: 'route'
    },
];