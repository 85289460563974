import * as Common from 'constants/Constants'

export const addHash = (hash) => {
  return {
		type: Common.ADD_HASH,
		hash
	}
}

export const changeMenu = (currentMenu) => {
	return {
		type: Common.CHANGE_MENU,
		currentMenu
	}
}

export const toggleChangeMenu = (toggleMenu) => {
	return {
		type: Common.TOGGLE_MENU,
		toggleMenu
	}
}

export const inputChange = (value,key) => {

	let array = {};

	array[key] = value;

	return {
		type: Common.INPUT_CHANGE,
		value: array
	}
}

export const sendFlgChange = (value) => {
	return {
		type: Common.SEND_FLG_CHANGE,
		value
	}
}

export const validFormCheck = (array) => {
	return {
		type: Common.VALID_FORM,
		value: array
	}
}

export const setErrorMessage = (value) => {
	return {
		type: Common.ERROE_MESSAGE,
		value
	}
}

export const sendingForm = (value) => {
	return {
		type: Common.SENDING,
		value
	}
}


export const initForm = () => {
	return {
		type: Common.FROM_INIT
	}
}