import { createStore as reduxCreateStore, applyMiddleware, combineReducers, compose } from "redux";
import { commonReducer } from "store/reducers/Common";
import { cmsReducer } from "store/reducers/Cms";
import thunk from 'redux-thunk';

export default function createStore(state) {
  const store = reduxCreateStore(
    combineReducers({
      router: "",
      common: commonReducer,
      cms: cmsReducer
    }),
    state,
    compose(
      applyMiddleware(
        thunk
      ),
    )
  );

  return store;
}


export let store = null;
export function getStore() { return store; }
export function setAsCurrentStore(s) {
  store = s;
  if (process.env.NODE_ENV !== 'production'
      && typeof window !== 'undefined') {
      window.store = store;
  }
}