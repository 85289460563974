import React from 'react';
import MdCodeWorking from 'react-ionicons/lib/MdCodeWorking';
import MdGlobe from 'react-ionicons/lib/MdGlobe';
import IosBrush from 'react-ionicons/lib/IosBrush';
import { withTranslation } from 'react-i18next';
import Helmet from "components/Instances/Instances/ScrollBtn/Helmet";

class Skills extends React.Component {

  render() {
    // propsから値取り出し
    const { t } = this.props;
    return (
        <div className="section-wrapper twoside center-vh dir-col anim">
            <Helmet
                title={`${t('skills')} | ${t('karpeles_lab')}`}
            />
            <div className="anim-2 text-center mb-3">
                <h2 className="display-4 display-title">{t('skills')}</h2>
                <p>{t('skills_p')}</p>
            </div>

            <div className="item row justify-content-between fade-1">
                <div className="col-12 col-md-4 col-lg-4">
                    <div className="section-content">
                        <div className="media">
                            <div className="media-body text-center">
                                <i className="icon">
                                <MdCodeWorking fontSize="48px" color="rgb(80, 76, 137)" />
                                </i>
                                <h3>{t('development')}</h3>
                                <p>{t('development_p')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                    <div className="section-content">
                        <div className="media">
                            <div className="media-body text-center">
                                <i className="icon">
                                <MdGlobe fontSize="48px" color="rgb(80, 76, 137)" />
                                </i>
                                <h3>{t('creativity')}</h3>
                                <p>{t('creativity_p')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                    <div className="section-content">
                        <div className="media">
                            <div className="media-body text-center">
                                <i className="icon">
                                <IosBrush fontSize="48px" color="rgb(80, 76, 137)" />
                                </i>
                                <h3>{t('innovation')}</h3>
                                <p>{t('innovation_p')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
};

export default withTranslation()(Skills);
