import React from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { rest } from '@karpeleslab/klbfw';
import classNames from 'classnames'
import * as Actions from 'store/actions/Actions';
import Helmet from "components/Instances/Instances/ScrollBtn/Helmet";


// sass
import 'sass/Contact.scss';


class Contact extends React.Component {

    render() {
        const {
            t,
            state:{
                common:{
                    form:{
                        inputs,
                        error,
                        sendFlg,
                        errorMessage,
                        sending
                        }
                    }
                },
            inputChange,
            sendFlgChange,
            validFormCheck,
            setErrorMessage,
            sendingForm,
            initForm
        } = this.props;


        const valueSetState = (event) => {
            inputChange(event.target.value, event.target.name);
        }

        const validForm = () => {
            validFormCheck(
                {
                    name: inputs.name === "",
                    email: inputs.email === "",
                    subject: inputs.subject === "",
                    message: inputs.message === ""
                }
            )

            const valid = inputs.name !== "" && inputs.email !== "" && inputs.subject !== "" && inputs.message !== "";

            return valid
        }

        const formSubmit = () => {
            sendingForm(true);

            const data =  {
                'To' : inputs.to,
                'Subject' : inputs.subject,
                'Message' : inputs.message,
                'Email' : inputs.email,
                'Name': inputs.name
            };

            setErrorMessage('');

            if (validForm()) {
                rest("Support/Ticket", "POST", data).then(
                    (data) => {
                        sendingForm(false);
                        sendFlgChange(true);
                        initForm();
                    }, (reason) => {
                        sendingForm(false);
                        setErrorMessage(reason.message);
                    }
                ).catch((data) => {
                    sendingForm(false);
                    setErrorMessage(data.message);
                });
            } else {
                sendingForm(false);
                setErrorMessage(t('not_entered'));
            }
        }


        return (
            <div className="section-wrapper center-vh dir-col anim">
                <Helmet
                    title={`${t('menu_contact')} | ${t('karpeles_lab')}`}
                />
                <div className="section-title text-center">
                    <h2 className="display-4 display-title">{t('menu_contact')}</h2>
                    <p>{t('contact_us_p')}</p>
                </div>

                {sendFlg &&
                <div className="col-12 col-md-12 col-lg-7 text-center message_sent">
                    <p className="message-ok form-text-feedback form-success-visible">{t('contact_message_sent_notification')}</p>
                </div>
                }

                <div className="section-content reduced">
                    <div className="row">


                        {errorMessage.length !== 0 &&
                        <div className="col-12 col-md-12 col-lg-7 text-center contact-left error_message">{errorMessage}</div>
                        }

                        <div className="col-12 col-md-12 col-lg-7 text-left contact-left preparing_message">

                            <div className="form-group name">
                                <label htmlFor="contact_name">{t('contact_name')}</label>
                                <input
                                    id="contact_name"
                                    onChange={valueSetState}
                                    value={inputs.name}
                                    name="name"
                                    type="text"
                                    placeholder=""
                                    className={classNames('form-control-line form-success-clean', {'is-error' : error.name})}
                                />
                            </div>
                            <div className="form-group email">
                                <label htmlFor="contact_email">{t('contact_email')}</label>
                                <input
                                    id="contact_email"
                                    onChange={valueSetState}
                                    value={inputs.email}
                                    type="email"
                                    placeholder=""
                                    name="email"
                                    className={classNames('form-control-line form-success-clean', {'is-error' : error.email})}
                                />
                            </div>
                            <div className="form-group subject">
                                <label htmlFor="contact_subject">{t('contact_subject')}</label>
                                <input
                                    id="contact_subject"
                                    onChange={valueSetState}
                                    value={inputs.subject}
                                    type="text"
                                    placeholder=""
                                    name="subject"
                                    className={classNames('form-control-line form-success-clean', {'is-error' : error.subject})}
                                />
                            </div>
                            <div className="form-group no-border">
                                <label htmlFor="contact_message">{t('contact_message')}</label>
                                <textarea
                                    id="contact_message"
                                    onChange={valueSetState}
                                    value={inputs.message}
                                    name="message"
                                    className={classNames('form-control form-control-outline thick form-success-clean', {'is-error' : error.message})}
                                ></textarea>
                            </div>
                            <div className="btns">
                                <button
                                    className="btn btn-outline-white btn-full"
                                    name="submit_message"
                                    onClick={formSubmit}
                                    disabled={sending}
                                    >
                                    <i className="icon ion-email mr-2"></i>
                                    <span className="txt">{t('contact_send')}</span>
                                    <span className="arrow-icon"></span>
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }
};


const mapStateToProps = state => {
    return {
        state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(Actions, dispatch),
    }
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Contact);
