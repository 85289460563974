import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Helmet from "components/Instances/Instances/ScrollBtn/Helmet";
import {syncCms} from 'store/actions/Cms';
import IosRefresh from 'react-ionicons/lib/IosRefresh';


import styles from 'sass/Cms.module.scss';

class Cms extends React.Component {
  constructor(props) {
    super(props);

    // propsから値取り出し
    this.state = {
      title: null,
      article: null
    }
  }


  componentDidMount() {
    this.props.syncCms(this.props.match);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.match.params.key !== prevProps.match.params.key)
      this.props.syncCms(this.props.match);
  }

  render() {
    const {toggleMenu, cms, isLoading} = this.props;

    const HeadTitle = () => {
      if (isLoading) {
        return <Helmet title="loading" />
      } else if (!isLoading && cms.Vars !== void 0) {
        if (cms.Vars._cms_entry_data) {
          return <Helmet title={cms.Vars._cms_entry_data.Title} />
        } else {
          return <Helmet title="404 Page Not Found" />
        }
      }
    }

    const Content = () => {
      if (isLoading) {
        return <Loader />
      } else if (!isLoading && cms.Vars !== void 0) {
        if (cms.Vars._cms_entry_data) {
          return <ResultContent />
        } else {
          return <NotContent />
        }
      }
    }

    const ResultContent = () => {
      return (
        <div className="section-wrapper center-vh dir-col anim">
          <div className="section-content text-center">
            <div className="row">
              <div className="col-12 col-md-12 text-left">
                <div className=" text-center">
                  <h4 className="display-4 display-title">{cms.Vars._cms_entry_data.Title}</h4>
                </div>
                <div dangerouslySetInnerHTML={{__html : cms.Vars._cms_entry_data.Contents}} />
              </div>
            </div>
          </div>
        </div>
      )
    }

    const NotContent = () => {
      return (
        <div className="section-wrapper center-vh dir-col anim">
          <div className="section-content text-center">
            <div className="row">
              <div className="col-12 col-md-12 text-left">
                <div className="text-center">
                  <h4 className="display-4 display-title">404 Page Not Found</h4>
                </div>
                <div className="text-center">
                  <p>URLをお確かめの上、再度アクセスを行なってください。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }


    const Loader = () => {
      return (
        <div className={styles['loader-wrapper']}>
          <IosRefresh className={styles['loader']} fontSize="60px" color="#504c89" rotate={true} />
        </div>
      )
    }

    return (
      <main
        className={classNames('page-main page-fullpage main-anim',{
          'menu-visible' : toggleMenu
        })}  
        id="mainpage"
      >
        <HeadTitle />
        <Content />
      </main>

    );
  }
};

const mapStateToProps = state => {
  return {
    toggleMenu: state.common.toggleMenu,
    cms: state.cms.cms,
    isLoading: state.cms.sync_cms,
    error: state.cms.sync_cms_error,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    syncCms: (match) => dispatch(syncCms(match))
  };
}


Cms.serverFetch = syncCms;

export default connect(mapStateToProps, mapDispatchToProps)(Cms);
