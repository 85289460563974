import React from 'react';
import {Provider} from "react-redux";
import {renderToString} from 'react-dom/server';
import {matchPath, StaticRouter} from 'react-router-dom';
import createStore, {setAsCurrentStore} from "../store/createStore";
import Layout from 'components/Layouts/Layout';
import routeConfig from './routeConfig'
import {getUrl, getPrefix, getPath, getUuid} from "@karpeleslab/klbfw";
import {Helmet} from "react-helmet";
import {i18nPromise} from 'i18n';

export default (cbk) => {
    let result = {
        uuid: getUuid(),
        app: null,
        title: null,
        meta: null,
        script: null,
        link: null,
        initial: null,
        error: null,
        redirect: null,
        bodyAttributes: null,
        htmlAttributes: null
    };

    const store = createStore();
    setAsCurrentStore(store);

    try {
        const promises = [i18nPromise];

        routeConfig.some(route => {
            const match = matchPath(getPath(), route);
            if (match && route.loadData !== undefined) {
                route.loadData.forEach((f) => promises.push(store.dispatch(f(match))))
            }
            return match;
        });

        let search = "";
        let getUrlResult = getUrl();
        if (typeof getUrlResult.query !== "undefined") {
                search = "?" + getUrlResult.query;
        }

        Promise.all(promises).then(() => {
            let context = {};

            result.app = renderToString(
                <Provider store={store}>
                    <StaticRouter context={context} basename={getPrefix()} location={{ pathname: getPrefix()+getPath(), search: search }}>
                        <Layout/>
                    </StaticRouter>
                </Provider>
            );

            result.initial = JSON.stringify(store.getState());

            Helmet.canUseDOM = false

            const helmet = Helmet.renderStatic();
            result.title = helmet.title ? helmet.title.toString() : null;
            result.meta = helmet.meta ? helmet.meta.toString() : null;
            result.script = helmet.script ? helmet.script.toString() : null;
            result.link = helmet.link ? helmet.link.toString() : null;
            result.bodyAttributes = helmet.bodyAttributes ? helmet.bodyAttributes.toString() : null;
            result.htmlAttributes = helmet.htmlAttributes ? helmet.htmlAttributes.toString() : null;
            console.log(JSON.stringify(helmet));

            return cbk(result);
        });
    } catch (e) {
        result.error = e;
        return cbk(result);
    }
}
