import React from 'react';
import 'swiper/css/swiper.css';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Helmet from "components/Instances/Instances/ScrollBtn/Helmet";


// img 
import Mark from 'assets//img/mark.png';
import Matthieu from 'assets//img/matthieu.png';
import Aoki from 'assets//img/aoki.png';
import Takafumi from 'assets/img/member-takafumi.jpg';

// sass
import 'sass/Team.scss';

class Team extends React.Component {
  constructor(props) {
    super(props);
    // propsから値取り出し
    this.state = {
      showDetail: false,
      showUserName: null,
    }
  }

	showDetail(userName,fullApi) {
    this.setState({
      showDetail: true,
      showUserName: userName
    });
  }

  closeDetail() {
    this.setState({
      showDetail: false,
      showUserName: null
    });
  }

  createMarkup(text) {
    return{__html: text.replace(/\r?\n/g, '</br>')}
  }

  render() {
    const { t } = this.props;

    const useDataList = {
      mark: {
        img: Mark,
        name: t('name_mark_karpeles'),
        role: t('ceo'),
        detailText: t('bio_mark_karpeles')
      },
      matthieu: {
        img: Matthieu,
        name: t('name_matthieu_bastianel'),
        role: t('backend_dev'),
        detailText: t('bio_matthieu_bastianel')
      },
      aoki: {
        img: Aoki,
        name: t('name_aoki'),
        role: t('title_aoki'),
        detailText: t('bio_aoki')
      },
      takafumi: {
        img: Takafumi,
	name: t('name_takafumi'),
	role: t('title_takafumi'),
	detailText: t('bio_takafumi')
      }
    }

    const userList = [];
    _.forEach(useDataList, (value, key) => {
      userList.push(
        <div className="col-12 col-sm-6 col-md-2 center-vh"  key={key}>
          <div className="section-content anim translateUp">
            <div className="images text-center">
              <div className="img-avatar-alpha">
                <div className="img-1 shadow">
                  <span
                    className="show-detail"
                    onClick={() => this.showDetail(key)}>
                      <img className="img" src={value.img} alt={value.name} />
                  </span>
                  <div className="legend pos-abs">
                    <h5>{value.name}</h5>
                    <p className="small">{value.role}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    });

    return (

      <div className="section-wrapper center-vh dir-col anim">
        <Helmet
            title={`${t('menu_team')} | ${t('karpeles_lab')}`}
        />
        <div className="section-title text-center">
            <h2 className="display-4 display-title anim-2 mb-32">{t('menu_team')}</h2>
        </div>
        
        {!this.state.showDetail ? (
        <div className="section-content anim text-center lst-team">
          <div className="item row justify-content-between">

            {userList}

          </div>
        </div>
        ) : ''}
        

        {this.state.showDetail ? (
        <div className="section-content anim text-center bio">
            <div className="item row justify-content-between">
                <div className="col-12 col-sm-6 col-md-4 center-vh">
                    <div className="section-content anim translateUp">
                        <div className="images text-center">
                            <div className="img-avatar-alpha">
                                <div className="img-1 shadow bio">
                                
                                    <img className="img" src={useDataList[this.state.showUserName].img} alt={useDataList[this.state.showUserName].name}/>
                               
                                    <div className="legend pos-abs">
                                    <h5>{useDataList[this.state.showUserName].name}</h5>
                                        <p className="small">{useDataList[this.state.showUserName].role}</p>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-8 center-vh bio">
                    <span className="backtoteam"
                    onClick={() => this.closeDetail()}>X</span>
                    <p
                      className="bio-text"
                      dangerouslySetInnerHTML={this.createMarkup(useDataList[this.state.showUserName].detailText)}></p>
                </div>
            </div>
        </div>
        ) : ''}

      </div>
    );
  }
};

export default withTranslation()(Team);
