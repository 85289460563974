import * as Common from 'constants/Constants';

const initState = {
  cms: [],
  sync_cms: true,
  sync_cms_error: null,
};

export const cmsReducer = (state = initState, action) => {
  switch (action.type) {
      case Common.SYNC_CMS:
          return {...state, sync_cms: true};
      case Common.SYNC_CMS_ERROR:
          return {...state, sync_cms: false, sync_cms_error: action.error};
      case Common.SYNC_CMS_DONE:
        console.log()
          return {...state, sync_cms: false, cms: action.cms};
      default:
          return state
  }
}