import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { withTranslation } from 'react-i18next';
import 'sass/Project.scss';
import Helmet from "components/Instances/Instances/ScrollBtn/Helmet";

// img 
import Cloud from 'assets/img/cloud.png';
import Chat from 'assets/img/chat.png';
import Os from 'assets/img/os.png';
import Shells from "assets/img/shells.png";

class Project extends React.Component {
  constructor(props) {
    super(props);
    // propsから値取り出し
    this.state = {
      params: {
        loop: false,
        autoplay: {
          delay: 5000
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        spaceBetween: 0,
      }
    }
  }
  render() {
    const { t } = this.props;
    return (
      <div className="section-wrapper twoside dir-col anim">
        <Helmet
            title={`${t('menu_projects')} | ${t('karpeles_lab')}`}
        />
        <div className="section-title center-vh  dir-col text-center">
            <h2 className="display-4 display-title anim-2 mb-32">{t('menu_projects')}</h2>
        </div>
        
        <div className="slider-wrapper carousel-swiper-beta fade-1 carousel-smalls carousel-swiper-beta-demo">
          <div className="slider-container swiper-container">
            <Swiper
              className="item-list swiper-wrapper"
              {...this.state.params}>
                <div className="slide-item swiper-slide">
                  <div className="item-wrapper">
                      <div className="illustr">
                          <img src={Shells}
                                alt={t('project_shells')} className="img" />
                      </div>
                      <div className="project-desc">
                          <div className="legend">
                              <h2 className="display-4" style={{
                                textTransform: "none"
                              }}>{t('project_shells')}</h2>
                              <p>{t('project_shells_p')}</p>
                              <a className="project-link" target="_blank" rel="noopener noreferrer" href="https://www.shells.com/">{t("project_shells_link")}</a>
                          </div>
                      </div>
                  </div>
                </div>
                <div className="slide-item swiper-slide">
                  <div className="item-wrapper">
                      <div className="illustr">
                          <img src={Cloud}
                                alt={t('project_cloud_solutions')} className="img" />
                      </div>
                      <div className="project-desc">
                          <div className="legend">
                              <h2 className="display-4">{t('project_cloud_solutions')}</h2>
                              <p>{t('project_cloud_solutions_p')}</p>
                          </div>
                      </div>
                  </div>
                </div>
                <div className="slide-item swiper-slide">
                  <div className="item-wrapper">
                      <div className="illustr">
                          <img src={Chat}
                                alt={t('project_chat_system')} className="img" />
                      </div>
                      <div className="project-desc">
                          <div className="legend">
                              <h2 className="display-4">{t('project_chat_system')}</h2>
                              <p>{t('project_chat_system_p')}</p>
                          </div>
                      </div>
                  </div>
                </div>
                <div className="slide-item swiper-slide">
                  <div className="item-wrapper">
                      <div className="illustr">
                          <img src={Os}
                                alt={t('project_azusa')} className="img" />
                      </div>
                      <div className="project-desc">
                          <div className="legend">
                              <h2 className="display-4">{t('project_azusa')}</h2>
                              <p>{t('project_azusa_p')}</p>
                          </div>
                      </div>
                  </div>
                </div>
            </Swiper>
          </div>
        </div>
      </div>
    );
  }
};

export default withTranslation()(Project);
