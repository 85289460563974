import {rest} from '@karpeleslab/klbfw'
import {SYNC_CMS, SYNC_CMS_ERROR, SYNC_CMS_DONE} from "constants/Constants";

export const syncCms = (match) => {

    const query = {
        "base": 'page',
        "path": match.params.key,
        "class": 'Content/Cms',
        "id": 'ctcm-dkhkdr-2miz-dnld-tnho-ycfqz2ti'
    }

    return (dispatch, getState) => {
        dispatch({type: SYNC_CMS});

        return rest("Misc/HandleQuery:process", "GET", query)
            .then(
                (data) => {
                    dispatch({type: SYNC_CMS_DONE, cms: data.data})
                }
            )
            .catch(
                (data) => dispatch({type: SYNC_CMS_ERROR, error: data.message})
            )
    }
}
