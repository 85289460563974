import React from 'react';
import { bindActionCreators } from 'redux';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import classNames from 'classnames'
import * as Actions from 'store/actions/Actions';
import { NavLink } from "react-router-dom";


// style
import 'sass/App.scss';

class Header extends React.Component {

  render() {
    const { t, toggleChangeMenu, state:{common: {toggleMenu}}} = this.props;

    const toggle = () => {
        if (!toggleMenu) {
            toggleChangeMenu(true);
        } else {
            toggleChangeMenu(false);
        }
    }


    const PcMenuListLeft = () => {
        return (
            <ul className="navbar-nav nav-first-margin">
                <li className="nav-item">
                    <NavLink
                        className="nav-link scroll"
                        exact
                        activeClassName='active'
                        to={`/`}
                    >{t('menu_home')}</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        className="nav-link scroll"
                        activeClassName='active'
                        to={`/skills`}
                    >{t('menu_skills')}</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        className="nav-link scroll"
                        activeClassName='active'
                        to={`/projects`}
                    >{t('menu_projects')}</NavLink>
                </li>
            </ul>
            );
    }


    const PcMenuListRight = () => {

        return (
            <ul className="navbar-nav nav-second-margin">
                <li className="nav-item">
                    <NavLink
                        className="nav-link scroll"
                        activeClassName='active'
                        to={`/team`}
                    >{t('menu_team')}</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        className="nav-link scroll"
                        activeClassName='active'
                        to={`/page/company-info`}
                    >{t('menu_company')}</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        className="nav-link scroll"
                        activeClassName='active'
                        to={`/contact`}
                    >{t('menu_contact')}</NavLink>
                </li>
            </ul>
        );
    }

    
    return (
      <div className="Header">
        <header className={classNames('page-header navbar page-header-alpha scrolled-white menu-right topmenu-right')}>
          <button
            className={classNames('navbar-toggler site-menu-icon', {
                'menu-visible' : toggleMenu
            })}
            id="navMenuIcon"
            onClick={()=>{
                toggle();
            }}
            >
              <span className={classNames('menu-icon menu-icon-random', {
                'menu-visible' : toggleMenu
            })}>
              <span className="bars">
                <span className="bar1"></span>
                <span className="bar2"></span>
                <span className="bar3"></span>
              </span>
            </span>
          </button>

          <div className={classNames('all-menu-wrapper', {
                'menu-visible' : toggleMenu
            })} id="navbarMenu">
              <nav className="navbar-mainmenu">
                  <div className="navbar-nav mr-auto mr-auto-my">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link scroll"
                                    to={`/`}
                                    exact
                                    activeClassName=''
                                    onClick={()=>{
                                        toggleChangeMenu(false)
                                    }}
                                >{t('menu_home')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link scroll"
                                    to={`/skills`}
                                    activeClassName=''
                                    onClick={()=>{
                                        toggleChangeMenu(false)
                                    }}
                                >{t('menu_skills')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link scroll"
                                    to={`/projects`}
                                    activeClassName=''
                                    onClick={()=>{
                                        toggleChangeMenu(false)
                                    }}
                                >{t('menu_projects')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link scroll"
                                    to={`/team`}
                                    activeClassName=''
                                    onClick={()=>{
                                        toggleChangeMenu(false)
                                    }}
                                >{t('menu_team')}</NavLink>
                            </li>
                        </ul>
                        <ul className="navbar-nav">
                          <li className="nav-item">
                              <NavLink
                                className="nav-link scroll"
                                to={`/page/company-info`}
                                activeClassName=''
                                onClick={()=>{
                                    toggleChangeMenu(false)
                                }}
                            >{t('menu_company')}</NavLink>
                          </li>
                          <li className="nav-item">
                              <NavLink
                                className="nav-link scroll"
                                to={`/contact`}
                                activeClassName=''
                                onClick={()=>{
                                    toggleChangeMenu(false)
                                }}
                                >{t('menu_contact')}</NavLink>
                          </li>
                      </ul>
                  </div>
              </nav>
          </div>

          <nav className="navbar navbar-top-default center-logo navbar-expand-lg nav-line">
            <div className="container">
                <NavLink 
                    exact
                    to={`/`}>
                    <span className="logo scroll"><Trans i18nKey="karpeles_lab_title">カルプレス<span className="logo-inner">研究所</span></Trans></span>
                </NavLink>
                    <div className="collapse navbar-collapse" id="menu-list">
                        <PcMenuListLeft />
                        <PcMenuListRight />
                    </div>
                </div>
          </nav>
        </header>

      </div>
    );
  }
};


const mapStateToProps = state => {
    return {
        state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(Actions, dispatch),
    }
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Header);
