import React from 'react';
import ReactHelmet from "react-helmet";

class Helmet extends React.Component {
  render() {
    return (
        <ReactHelmet>
          <title>{this.props.title}</title>
        </ReactHelmet>
    );
  }
};

export default Helmet;