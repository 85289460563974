import React from 'react';
import { withTranslation } from 'react-i18next';
import Mv from 'assets//img/logo.png';
import Helmet from "components/Instances/Instances/ScrollBtn/Helmet";

class Home extends React.Component {
    render() {
        // propsから値取り出し
        const { t } = this.props;
        return (
            <div className="section-wrapper center-vh dir-col anim">
                <Helmet
                    title={t('karpeles_lab')}
                />
                <div className="section-content reduced text-center">
                    <div className="row">
                        <div className="col-12 col-md-12 text-center">
                            <div className="title-desc">
                                <h2 className="display-4 display-title home-title anim-1">
                                    <div className="row">
                                        <div className="col-12">
                                            <img
                                                className="main-visual"
                                                src={Mv}
                                                alt={t('index_slogan')} />
                                        </div>
                                    </div>
                                </h2>
                                <h4 className="anim-2 opacity-75">{t('index_slogan')}</h4>
                            </div>

                            <div className="btns-action anim-3 hidden">
                                <a className="btn btn-outline-white btn-creative" href="#skills">
                                    <span className="text">強み</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default withTranslation()(Home);
